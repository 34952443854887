<template>
  <v-row class="app-quotation-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card v-if="quotation">
        <!-- Header -->
        <v-card-text class="pa-8">
          <div class="quotation-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
            <!-- Left Content -->
            <div class="mb-8 mb-sm-0">
              <div class="d-flex align-center mb-6">
                <v-img
                  :src="organization.logo_url || require('@/assets/images/logo.png')"
                  max-height="30px"
                  max-width="30px"
                  alt="logo"
                  contain
                  class="me-3"
                ></v-img>
                <span class="text--primary font-weight-bold text-xl">
                  {{ organization.display_name }}
                </span>
              </div>
              <span class="d-block">{{ organization.name }} ({{ organization.roc_number }})</span>
              <span class="d-block">{{ organization.address }}</span>
              <span class="d-block">Tel: {{ organization.contact }}</span>
              <span class="d-block">{{ organization.website }}</span>
            </div>
            <!-- Right Content -->
            <div>
              <p class="font-weight-medium text-xl text--primary mb-4">
                Quotation #{{ quotation.quotation_number }}
              </p>
              <table class="text-right ml-0 ml-sm-auto mr-0">
                <tr>
                  <td class="pe-2">
                    Date Issued:
                  </td>
                  <td class="text-left">
                    {{ utilsService.formatDate(quotation.quotation_date) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Customer Details -->
        <v-card-text class="pa-8">
          <div class="d-flex">
            <div>
              <table class="w-full">
                <tr>
                  <th class="text-left pb-2">
                    Quotation To:
                  </th>
                </tr>
                <template v-if="quotation.customer">
                  <tr>
                    <td>
                      {{ quotation.customer.name }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ quotation.customer.company }}
                    </td>
                  </tr>
                  <tr v-if="quotation.customer.address">
                    <td>
                      {{ quotation.customer.address }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ quotation.customer.contact_number }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ quotation.customer.email }}
                    </td>
                  </tr>
                </template>
              </table>
            </div>
            <v-spacer></v-spacer>
            <div>
              <table class="text-right ml-auto mr-0">
                <tr>
                  <td class="pe-2">
                    Issued By:
                  </td>
                  <td class="text-left">
                    {{ quotation.issued_by }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <!-- Table -->
        <v-simple-table class="purchased-items-table d-none d-sm-block">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  ITEM
                </th>
                <th>
                  UNIT PRICE
                </th>
                <th>
                  QUANTITY
                </th>
                <th>
                  DISCOUNT
                </th>
                <th>
                  AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="line_item in quotation.line_items_attributes"
                :key="line_item.id"
              >
                <td class="text-no-wrap py-2">
                  {{ line_item.item.name }}<br>
                  <small class="text-wrap">{{ line_item.item.description }}</small>
                </td>
                <td class="text-no-wrap">
                  {{ line_item.price }}
                </td>
                <td>
                  {{ line_item.quantity }}
                </td>
                <td>
                  {{ line_item.discount }}
                </td>
                <td>
                  {{ line_item.amount }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- table view for mobile -->
        <v-data-table
          :headers="tableSmHeaders"
          :items="quotation.line_items_attributes"
          :expanded.sync="expanded"
          single-expand
          show-expand
          mobile-breakpoint="0"
          hide-default-footer
          class="fixed-action d-block d-sm-none"
        >
          <!-- Amount -->
          <template #[`item.amount`]="{item}">
            <div>${{ item.amount }}</div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              class="table-expand"
              :colspan="headers.length"
            >
              <p class="mt-2">
                Unit Price: ${{ item.price }}
              </p>
              <p>Quantity: {{ item.quantity }}</p>
              <p>Discount: ${{ item.discount }}</p>
              <p
                class="text-wrap"
                v-html="item.item.description"
              ></p>
            </td>
          </template>
        </v-data-table>

        <!-- Total -->
        <v-card-text
          v-if="quotation.line_items_attributes"
          class="pa-8"
        >
          <div class="quotation-total d-flex justify-space-between flex-column flex-sm-row">
            <v-spacer></v-spacer>
            <div>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    Subtotal:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(quotation.line_items_attributes.reduce((sum, item) => sum + (parseFloat(item.price) * item.quantity), 0)).toFixed(2) }}
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">
                    Total Discount:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(quotation.line_items_attributes.reduce((sum, item) => sum + parseFloat(item.discount), 0)).toFixed(2) }}
                  </th>
                </tr>
              </table>
              <v-divider class="my-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    Total:
                  </td>
                  <th class="text-right">
                    ${{ quotation.total.toFixed(2) }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="pa-8 d-flex">
          <p class="mb-0 font-weight-semibold mb-3 pr-3">
            Note:
          </p>
          <span v-html="quotation.note"></span>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Right Column: Quotation Action -->
    <v-col
      cols="12"
      md="3"
    >
      <v-card>
        <v-card-text>
          <v-btn
            class="mb-4"
            color="primary"
            block
            :loading="downloadLoading"
            :disabled="downloadLoading"
            @click="downloadQuotation"
          >
            Download
          </v-btn>
          <v-btn
            class="mb-4"
            color="primary"
            block
            :loading="reminderLoading"
            :disabled="reminderLoading"
            @click="remindQuotation"
          >
            Send Via Email
          </v-btn>
          <v-btn
            class="mb-4"
            color="secondary"
            block
            outlined
            :to="{ name: 'quotation-form', params: { id: $route.params.id } }"
          >
            Edit Quotation
          </v-btn>
          <v-btn
            color="secondary"
            block
            outlined
            :to="{ name: 'invoice-form', params: { quotation_id: quotation.id } }"
          >
            Create Invoice
          </v-btn>
        </v-card-text>
      </v-card>

      <ActivityLog :activities="activities" />
    </v-col>
  </v-row>
</template>

<script>
import {
  ref,
  onMounted,
  computed,
  inject,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { saveAs } from 'file-saver'
import ActivityLog from '@/components/activity/ActivityLog'

export default {
  components: {
    ActivityLog,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')
    const { route } = useRouter()
    const quotationId = route.value.params.id
    const organizationData = computed(() => store.state.organization)

    // Properties
    const quotation = ref({})
    const organization = ref({ ...organizationData.value })
    const downloadLoading = ref(false)
    const reminderLoading = ref(false)

    const activities = ref([])

    // Table Handlers
    const expanded = ref([])
    const tableSmHeaders = [
      { text: 'ITEM', value: 'item.name' },
      { text: 'AMOUNT', value: 'amount', sortable: false },
      { text: '', value: 'data-table-expand' },
    ]

    // Methods
    const fetchQuotation = () => {
      store
        .dispatch('quotationStore/fetchQuotation', { id: quotationId })
        .then(response => {
          quotation.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching items. Please refresh!')
        })
    }
    const downloadQuotation = () => {
      downloadLoading.value = true
      store
        .dispatch('quotationStore/downloadQuotation', { id: quotationId })
        .then(response => {
          saveAs(response, `${quotation.value.quotation_number}.pdf`)
          downloadLoading.value = false
        })
        .catch(error => {
          downloadLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while downloading quotation. Please refresh!')
        })
    }
    const remindQuotation = () => {
      reminderLoading.value = true
      store
        .dispatch('quotationStore/remindQuotation', { id: quotationId })
        .then(response => {
          snackbarService.success(response.data.message)
          reminderLoading.value = false
        })
        .catch(error => {
          reminderLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while sending email reminder. Please refresh!')
        })
    }
    const fetchActivities = () => {
      store
        .dispatch('quotationStore/fetchActivities', { id: quotationId })
        .then(response => {
          activities.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching activities. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => {
      fetchQuotation()
      fetchActivities()
    })

    return {
      // Initializers
      utilsService,

      // Properties
      quotation,
      organization,
      downloadLoading,
      reminderLoading,
      activities,

      // Table Handlers
      expanded,
      tableSmHeaders,

      // Methods
      downloadQuotation,
      remindQuotation,
    }
  },
}
</script>
